<template>
  <TableTransactionsDistribution
    store="accounting.transactions"
    class="mb-4"
    :default_filter="default_filter"
  />
</template>

<script>
import TableTransactionsDistribution from "../../components/TableTransactionsDistribution.vue";

export default {
  components: { TableTransactionsDistribution },
  computed: {
    default_filter() {
      return {
        sort: "-voucher_date",
        'filter[remaining_distribution]': 1
      };
    },
  },
};
</script>
